import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid';

import oktavate from "../assets/images/oktavate.png";
import oktaFit from "../assets/images/oktafitoutpost.png";
import oktavateSwagStore from "../assets/images/okta-swag-store.png";

export default class Home extends Component {
    render() {
        return (
            <div style={{margin: "100px 0"}}>
                <Grid fluid>
                    <Row center="xs" middle="xs">
                        <Col xs={12} sm={6}>
                            <a href="https://oktavate.mybrightsites.com/">
                                <img src={oktavate} alt="depot logo" style={{height: "auto", maxWidth: "350px", width: "100%"}} />
                            </a>
                        </Col>
                        <Col xs={12} sm={6}>
                            <a href="https://okta.mybrightsites.com/">
                                <img src={oktavateSwagStore} alt="depot logo" style={{height: "auto", maxWidth: "300px", width: "100%"}} />
                            </a>
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}
