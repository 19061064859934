import React, { Component } from 'react';
import depotLogo from "./assets/images/swag-depot.png";
import Home from './components/Home'
import './assets/css/App.css';

export default class App extends Component {
    render() {
        return (
            <>
                <header style={{textAlign: "center"}}>
                    <img src={depotLogo} alt="depot logo" style={{height: "auto", maxWidth: "500px", width: "100%", margin: "50px 0 25px 0"}} />
                    
                </header>
                <Home />
                <footer>
                    Footer
                </footer>
            </>
        )
    }
}
